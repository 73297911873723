<template>
  <div>
    <div class="box mb15">
        <el-page-header @back="$router.go(-1)"></el-page-header>
    </div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="0" align="middle">
          <span class="text">{{$t('score.type')}}:</span>
          <el-radio-group size="medium" v-model="filter.type">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="1">{{type(1)}}</el-radio>
              <el-radio :label="2">{{type(2)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:amount="slotProps">
          <div>
            {{slotProps.callback.row.type === 1 ? '+' : '-'}}{{ slotProps.callback.row.amount }}
          </div>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { memberScore } from "@/api/index";
export default {
  name: "score",
  mixins: [mixin_list(memberScore)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
        user:this.$route.params.userId,
        type:""
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("score.create_time"),
          sortable: true,
          prop: "create_time",
        },
        // {
        //   label: this.$t("score.update_time"),
        //   sortable: true,
        //   prop: "update_time",
        // },
        // {
        //   label: this.$t("score.order_id"),
        //   sortable: true,
        //   prop: "order_id",
        // },
        {
          label: this.$t("score.amount"),
          sortable: true,
          slot:"amount",
          prop: "amount",
        },
        {
          label: this.$t("score.balance"),
          sortable: true,
          prop: "balance",
        },
      ],
    };
  },
  methods: {
      type(type){
          switch(type){
              case 1:
                  return this.$t('score.type1')
            case 2:
                  return this.$t('score.type2')
          }
      }
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:80px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>